import { useEffect } from "react";
import Provider from "./provider";
import { Router } from "./router/router";

import "./styles/index.css";

function App() {
  useEffect(()=>{
    //redirect to detrust.fund
      window.location.href = "https://detrust.fund";
  }, [])

  return (
    <div className="wrapper">
      <Provider>
        <Router />
      </Provider>
    </div>
  );
}

export default App;

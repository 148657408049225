import { Box, Stack, useTheme } from "@mui/material";
import { Suspense, useEffect, useLayoutEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { useSettingsContext } from "~/components/settings";
import { useIsDesktop } from "~/hooks/useIsDesktop";
import { Footer } from "~/modules/Footer/Footer";
import HeaderAuth from "~/modules/HeaderAuth/HeaderAuth";
import Menu from "~/modules/HeaderAuth/menuDrawer/Menu";
import { MenuDrawerProvider } from "~/modules/HeaderAuth/menuDrawer/MenuDrawerContext";
import { Loading } from "~/shared/ui";
import { getJwtInfo } from "~/shared/utils/jwt";

const LayoutPrivate = () => {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const { changeTheme } = useSettingsContext();

  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const jwtInfo = getJwtInfo();

  const isLeaderboard = location.pathname === "/leaderboard";
  const isAbout = location.pathname === "/about";

  const isAllowedPage = isLeaderboard || isAbout;

  useEffect(() => {
    if (!address) {
      localStorage.removeItem("jwt");
      if (!isAllowedPage) {
        navigate("/login");
      }
    }
    if (
      address &&
      jwtInfo &&
      jwtInfo.address.toLowerCase() !== address.toLowerCase() &&
      location.pathname !== "/login" &&
      location.pathname !== "/about" &&
      location.pathname !== "/leaderboard" &&
      location.pathname !== "/"
    ) {
      disconnect();
      localStorage.removeItem("jwt");
      if (!isAllowedPage) {
        navigate("/login");
      }
    }
  }, [address]);

  useEffect(() => {
    if (
      !jwtInfo &&
      location.pathname !== "/login" &&
      location.pathname !== "/about" &&
      location.pathname !== "/leaderboard" &&
      location.pathname !== "/"
    ) {
      disconnect();
      if (!isAllowedPage) {
        navigate("/login");
      }
    }
  }, [jwtInfo]);

  useLayoutEffect(() => {
    try {
      const settings = localStorage.getItem("settings");
      if (!!settings) return;
      if (window.matchMedia && window.matchMedia("(prefers-color-scheme: light)").matches) {
        changeTheme("light");
        return;
      }
      changeTheme("dark");
    } catch (error) {}
  }, []);

  const isSpin = location.pathname === "/spin";
  const { isAuthInit } = useWallet();

  // useEffect(() => {
  //   if (isAuthInit == false && !isAbout && !isLeaderboard) navigate("/");
  // }, [isAuthInit]);

  console.log("isAuthInit", isAuthInit);

  useEffect(()=>{
    //redirect to detrust.fund
      window.location.href = "https://detrust.fund";
  }, [])


  return (
    <Suspense fallback={<Loading />}>
      <MenuDrawerProvider>
        <Menu>
          <Stack
            alignItems={"center"}
            minHeight={"100vh"}
            bgcolor={theme.palette.background.default}
            sx={{
              overflowX: "hidden",
            }}
          >
            <HeaderAuth />
            <Stack
              width={"min(1200px,100%)"}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "100%",
                flex: 1,
                px: 2,
              }}
            >
              <Stack pt={isSpin ? 2 : 5} pb={isSpin ? 0 : 12} width="100%">
                <Outlet />
              </Stack>
            </Stack>
            {isDesktop && <Footer />}
          </Stack>
        </Menu>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            display: `${isDesktop ? "none" : ""}`,
          }}
        >
          {/* <MobileBottomNavigation /> */}
        </Box>
      </MenuDrawerProvider>
    </Suspense>
  );
};

export default LayoutPrivate;
